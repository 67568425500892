import { fetchOneEntry, fetchEntries, type BuilderContent } from "@builder.io/sdk-vue";

export interface BuilderIOData<T> {
    data?: T;
}

export interface BuilderIOEnrichedData<T> {
    value: {
        data: T;
    };
}

export class BuilderIOService {
    private publicApiKey : string;

    public constructor(publicApiKey : string) {
        this.publicApiKey = publicApiKey;
    }

    public getPublicApiKey() {
        return this.publicApiKey;
    }

    public async fetchOneModel<T>(model: string, urlPath: string, enrich?: boolean): Promise<BuilderIOData<T>> {
        const data = await fetchOneEntry({
            model: model,
            apiKey: this.publicApiKey,
            userAttributes: { 
                urlPath: urlPath,
            },
            enrich: (enrich || false)
        });

        if (data != null) {
            return data as BuilderIOData<T>;
        }
        return { data: undefined };
    }

    public async fetchManyModels<T>(model: string, urlPath : string, enrich?: boolean) : Promise<BuilderIOData<T>[]> {
        const data = await fetchEntries({
            model: model,
            apiKey: this.publicApiKey,
            userAttributes: {
                urlPath: urlPath,
            },
            enrich: (enrich || false)
        });

        if (data != null && data.length > 0 && data[0].data != null) {
            return data as BuilderIOData<T>[];
        }
        return [];
    }
}

export default function() {
    const runtimeConfig = useRuntimeConfig();
    const builderIoKey = runtimeConfig.public.builderIoKey;
    if (typeof builderIoKey === "string") {
        return new BuilderIOService(builderIoKey);
    }
    else {
        throw new Error("Unable to build Builder IO service, public key is invalid.");
    }
}