/*
    Toast Store

    Used to manage toast message state.
    Import into a component to publish a toast message to the toast feed.
*/

export type ToastSeverity = 'success' | 'warn' | 'error' | 'info';

export interface Toast {
    description: string;
    severity: ToastSeverity;
    duration?: number;
}

export const useToastStore = defineStore('toastStore', () => {
    const open = ref<boolean>();
    const toasts = ref<Toast[]>([]);

    function publish(description: string, severity: ToastSeverity, duration?: number) {
        open.value = true;
        toasts.value?.push({
            description,
            severity,
            duration
        });
    }

    function $reset() {
        open.value = false;
        toasts.value = [];
    }

    return { open, toasts, publish, $reset };
});