<template>
    <font-awesome-icon v-bind="props" :icon="getRegularFAIcon"/>
</template>

<script setup lang="ts">
import type {FontAwesomeIconProps} from "@fortawesome/vue-fontawesome";
import type {IconDefinition} from "@fortawesome/pro-regular-svg-icons";

interface IconProps extends Omit<FontAwesomeIconProps, "icon"> {
    icon: IconDefinition | string
    // Ensure the icon is loaded from all appropriate fontawesome packages that you might need (ex. solid, light, regular)
    packPrefix?: string | undefined
}

const props = defineProps<IconProps>()
const getRegularFAIcon = computed(() => {
    switch (typeof props.icon) {
        case "object":
            return {"icon": props.icon.icon, "prefix": props.packPrefix ?? "far", "iconName": props.icon.iconName}
        case "string":
            switch (props.icon) {
                case 'instagram':
                case 'facebook':
                case 'tiktok':
                case 'twitter':
                case 'youtube':
                case 'linkedin':
                    return ["fab", props.icon]
                case 'cart-plus':
                case 'circle':
                case 'star':
                case 'star-half':
                    return [props.packPrefix ?? "fas", props.icon]
                default:
                  return [props.packPrefix ?? "far", props.icon]
            }
    }
});

</script>